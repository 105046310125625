var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ma-3" },
    [
      _c("Snackbar", { ref: "snackbar" }),
      _vm._m(0),
      _c(
        "div",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              items: _vm.conclusion,
              headers: _vm.headers,
              "sort-by": "id",
              "sort-desc": "",
              "show-expand": "",
              "footer-props": {
                showFirstLastPage: true,
                showCurrentPage: true,
                firstIcon: "mdi-arrow-collapse-left",
                lastIcon: "mdi-arrow-collapse-right",
                prevIcon: "mdi-minus",
                nextIcon: "mdi-plus",

                "items-per-page-text": "Показать на странице",
                "items-per-page-all-text": "Все",
                "items-per-page-options": [50, 100, 200, -1],
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item.id",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.id) + " ")]
                },
              },
              {
                key: "item.status",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.status) + " ")]
                },
              },
              {
                key: "item.nickname",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.history?.user ? item.history.user.nickname : ""
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.userid",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(item.history?.user ? item.history.user.id : "") +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.created_at",
                fn: function ({ item }) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          new Date(Date.parse(item.created_at)).toLocaleString()
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "item.wallet",
                fn: function ({ item }) {
                  return [_c("WalletView", { attrs: { wallet: item.wallet } })]
                },
              },
              {
                key: "item.crypto",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.crypto.title) + " ")]
                },
              },
              {
                key: "item.amount",
                fn: function ({ item }) {
                  return [_vm._v(" " + _vm._s(item.amount) + " ")]
                },
              },
              {
                key: "expanded-item",
                fn: function ({ headers, item }) {
                  return [
                    _c("td", { attrs: { colspan: headers.length - 2 } }, [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Баланс до: ${item.balance_old.toLocaleString(
                                "ru-RU",
                                { style: "currency", currency: "USD" }
                              )}`
                            ) +
                            " "
                        ),
                      ]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              `Баланс после: ${item.balance_now.toLocaleString(
                                "ru-RU",
                                { style: "currency", currency: "USD" }
                              )}`
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "item.actions",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "green",
                        attrs: { dense: "", text: "" },
                        on: {
                          click: function ($event) {
                            return _vm.success(item)
                          },
                        },
                      },
                      [_vm._v("Принять ")]
                    ),
                    _c("TextInputDialog", {
                      attrs: {
                        buttonclass: "red",
                        buttontext: "Отклонить",
                        caption: "Причина",
                        tag: item,
                      },
                      on: { ok: _vm.cancel },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "Withdraws" }, [
      _c("h3", [_vm._v("Выводы")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }